input.login-input {
  @apply w-full rounded-lg bg-[#F2F0F1] px-3 py-4 text-xl text-black focus:border-transparent focus:ring-0 ipad-pro-land:py-2.5;
}

input.session-id-input {
  @apply w-full rounded-lg px-2 text-center font-medium text-black moz-appearance-textfield focus:border-transparent focus:ring-0;
}

input.session-id-input::-webkit-inner-spin-button {
  @apply webkit-appearance-none;
}
