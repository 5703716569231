@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./gt-walsheim-pro.css');
@import 'pure-react-carousel/dist/react-carousel.es.css';
@import 'react-notifications-component/dist/theme.css';

@layer components {
  @import 'styles/transitions/index';
  @import 'styles/components/index';
}

@layer utilities {
  @import 'styles/utilities.css';
}
