form.basic-white-form {
  @apply mb-4 w-auto min-w-300px rounded bg-white px-8 pb-8 pt-6 shadow-md;
}

form.white-form {
  @apply mb-4 rounded bg-white px-8 pb-8 pt-6 shadow-md;
}

input[type='text'].input-gray,
input[type='password'].input-gray,
input[type='phone'].input-gray,
input[type='email'].input-gray,
input[type='number'].input-gray {
  @apply w-full appearance-none rounded border bg-gray-100 px-3 py-2 leading-tight text-gray-800;
}

input.input-gray-error {
  @apply mb-3 w-full appearance-none rounded border border-red-500 bg-gray-100 px-3 py-2 leading-tight text-gray-800;
}

label.input-label {
  @apply mb-2 block text-sm text-gray-700;
}

label.badge > span {
  @apply ml-3 cursor-pointer rounded-full bg-white px-4 py-2 text-xl text-black;
}

label.badge > input[type='radio'] {
  @apply hidden;
}

label.badge > input[type='radio']:checked + span {
  @apply rounded-full bg-black px-4 py-2 text-xl text-white;
}

input[type='checkbox'].checkbox-gray {
  @apply rounded border-2 border-gray-300 p-3;
}

input[type='checkbox'].checkbox-gray:checked {
  @apply border-2 border-gray-300 bg-white text-black svg-tick-black;
}

input[type='checkbox'].checkbox-gray:focus {
  @apply ring-0;
}

input[type='checkbox'].checkbox-gray + span {
  @apply text-gray-300;
}

input[type='checkbox'].checkbox-gray:checked + span {
  @apply text-black;
}

input.underline-transparent {
  @apply border-0 border-b border-white bg-transparent py-1 text-2xl;
}

input.underline-transparent:focus {
  @apply border-b border-white ring-0;
}

label.checkbox-white {
  @apply inline-block text-gray-500 ipad-mini:flex;
}

label.checkbox-white input {
  @apply h-10 w-10 rounded border-white bg-transparent text-transparent;
}

label.checkbox-white span {
  @apply flex-1 text-right text-2xl leading-9 text-white;
}

label.checkbox-white input:hover,
label.checkbox-white input:checked:hover {
  @apply border-white bg-accentClassic;
}

label.checkbox-white input:checked {
  @apply border-white;
}

label.checkbox-white input:focus {
  @apply ring-0;
}
