.left-nav-menu-item {
  @apply py-5 pl-5 text-subHeading font-medium ipad-pro-land:py-4;
}

.left-nav-menu-item button {
  @apply inline-block w-full text-left;
}

.left-nav-active-menu-item {
  @apply bg-secondaryColour text-mainColour;
}
