.transition-fade-enter {
  @apply opacity-0;
}

.transition-fade-enter-active {
  @apply opacity-100 transition-all duration-300 ease-in;
}

.transition-fade-enter-done {
  @apply opacity-100;
}

.transition-fade-exit {
  @apply opacity-100;
}

.transition-fade-exit-active {
  @apply opacity-0 transition-all duration-300 ease-in;
}

.transition-fade-exit-done {
  @apply opacity-0;
}
