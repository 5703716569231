@font-face {
  font-family: 'GT Walsheim Pro Regular';
  src: url('https://db.onlinewebfonts.com/t/4417812509ea84242dcf790e829ca350.eot'); /* IE9*/
  src:
    url('https://db.onlinewebfonts.com/t/4417812509ea84242dcf790e829ca350.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://db.onlinewebfonts.com/t/4417812509ea84242dcf790e829ca350.woff2')
      format('woff2'),
    /* chrome firefox */
      url('https://db.onlinewebfonts.com/t/4417812509ea84242dcf790e829ca350.woff')
      format('woff'),
    /* chrome firefox */
      url('https://db.onlinewebfonts.com/t/4417812509ea84242dcf790e829ca350.ttf')
      format('truetype'),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url('https://db.onlinewebfonts.com/t/4417812509ea84242dcf790e829ca350.svg#GT Walsheim Pro Regular')
      format('svg'); /* iOS 4.1- */
}
