/* Slide Top */
.transition-slide-top-enter {
  @apply translate-y-full;
}

.transition-slide-top-enter-active {
  @apply translate-y-0 transition-all duration-500;
}

.transition-slide-top-enter-done {
  @apply translate-y-0;
}

.transition-slide-top-exit {
  @apply translate-y-0;
}

.transition-slide-top-exit-active {
  @apply -translate-y-full transition-all duration-500;
}

.transition-slide-top-exit-done {
  @apply -translate-y-full;
}

/* Slide Left */
.transition-slide-left-enter {
  @apply translate-x-full;
}

.transition-slide-left-enter-active {
  @apply translate-x-0 transition-all duration-500;
}

.transition-slide-left-enter-done {
  @apply translate-x-0;
}

.transition-slide-left-exit {
  @apply translate-x-0;
}

.transition-slide-left-exit-active {
  @apply -translate-x-full transition-all duration-500;
}

.transition-slide-left-exit-done {
  @apply -translate-x-full;
}

/* Slide Right */
.transition-slide-right-enter {
  @apply -translate-x-full;
}

.transition-slide-right-enter-active {
  @apply translate-x-0 transition-all duration-500;
}

.transition-slide-right-enter-done {
  @apply translate-x-0;
}

.transition-slide-right-exit {
  @apply translate-x-0;
}

.transition-slide-right-exit-active {
  @apply translate-x-full transition-all duration-500;
}

.transition-slide-right-exit-done {
  @apply translate-x-full;
}
