.slider {
  @apply relative w-full;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  @apply absolute;
}

.slider__track,
.slider__range {
  @apply h-1.5 rounded;
}

.slider__track {
  @apply z-1 w-full bg-gray-300;
}

.slider__range {
  @apply z-2 bg-sky-400;
}

.slider__left-value,
.slider__right-value {
  @apply mb-3 text-base text-black;
}

.slider__left-value {
  @apply bottom-0 left-0;
}

.slider__right-value {
  @apply bottom-0 right-0;
}

/* Removing the default appearance */
input[type='range'].thumb,
input[type='range'].thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

input[type='range'].thumb {
  @apply pointer-events-none absolute h-0 w-full shadow outline-none;
}

input[type='range'].thumb--zindex-3 {
  @apply z-3;
}

input[type='range'].thumb--zindex-4 {
  @apply z-4;
}

input[type='range'].thumb--zindex-5 {
  @apply z-5;
}

/* For Chrome browsers */
input[type='range'].thumb::-webkit-slider-thumb {
  @apply relative mt-2 h-5 w-5 cursor-pointer rounded-full border-none bg-white shadow pointer-events-all;
}

/* For Firefox browsers */
input[type='range'].thumb::-moz-range-thumb {
  @apply relative mt-2 h-5 w-5 cursor-pointer rounded-full border-none bg-white shadow pointer-events-all;
}
