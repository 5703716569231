.transition-lot-package-expand-enter {
  @apply max-h-0 opacity-0;
}
.transition-lot-package-expand-enter-active {
  @apply max-h-[1200px] transform-gpu opacity-100 transition-all duration-500 ease-in;
}
.transition-lot-package-expand-exit {
  @apply max-h-[500px] opacity-100;
}
.transition-lot-package-expand-exit-active {
  @apply mt-0 max-h-0 transform-gpu border-t-0 pb-0 pt-0 opacity-0 transition-all duration-300 ease-out;
}

.transition-lot-select-property-expand-enter {
  @apply max-h-0;
}
.transition-lot-select-property-expand-enter-active {
  @apply max-h-[800px] transform-gpu transition-all duration-300 ease-in;
}
.transition-lot-select-property-expand-exit {
  @apply max-h-[200px];
}
.transition-lot-select-property-expand-exit-active {
  @apply max-h-0 transform-gpu transition-all duration-[400ms] ease-out;
}

.transition-lot-select-property-text-change-enter {
  @apply opacity-50;
}
.transition-lot-select-property-text-change-enter-active {
  @apply transform-gpu opacity-100 transition-opacity duration-200 ease-in;
}
.transition-lot-select-property-text-change-exit {
  @apply opacity-100;
}
.transition-lot-select-property-text-change-exit-active {
  @apply transform-gpu opacity-50 transition-opacity duration-200 ease-in;
}
